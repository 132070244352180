.count {
	background-color: var(--clr-afGray);
	color: white;
	display: inline-block;
	font-size: 0.7rem;
	line-height: 1rem;
	text-align: center;
	border-radius: 10px;
	min-width: 1rem;
	padding: 0 4px;
	margin-left: 5px;
	vertical-align: top;
}
