.label {
	width: 160px;
	border-radius: 4px;
	padding: 0 8px;
	margin-bottom: 1px;
	font-size: 0.8rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.label > :first-child {
	flex-basis: 20px;
	fill: var(--clr-afGray);
	display: flex;
}

.label > :nth-child(2) {
	flex-grow: 1;
}

.label > :nth-child(3) {
	display: flex;
	flex-basis: 14px;
}
