.createdFrom {
	margin-bottom: 0.25em;
	font-size: 0.8em;
	display: flex;
}

.createdFrom > span {
	background-color: #f7ccd2;
	border-radius: var(--br_small) 0 var(--br_small) 0;
	padding: 0 0.5em;
}
