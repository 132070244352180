.yearSelector {
	background-color: white;
	padding-bottom: 1em;
}

.content {
	display: flex;
	justify-content: space-between;
	column-gap: 3em;
	border-bottom-left-radius: var(--br_large);
	border-bottom-right-radius: var(--br_large);
	background-color: white;
}

.sidePanel {
	flex: 0 0 250px;
}

.sidePanel > *:not(:first-child) {
	margin-top: 1em;
}
