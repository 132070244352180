.panel {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.panel.small {
    max-width: 480px;
}

.panel.medium {
    max-width: 640px;
}

.panel.medium2 {
    max-width: 800px;
}

.panel.large {
    max-width: 1000px;
    /* max-height: calc(100vh - 6em); */
}

.panel.xLarge {
    max-width: 1200px;
}

.panel.maxWidth {
    padding-bottom: 1em;
}

.panel.full {
    position: absolute;
    left: 1em;
    top: 1em;
    right: 1em;
    bottom: 1em;
}

.error {
    background-color: var(--clr-afRed);
    color: white;
    padding: 0.5em 1em;
}

.header {
    position: relative;
    background-color: var(--clr-afBlue);
    padding: 0 1rem;
    border-top-left-radius: var(--br_large);
    border-top-right-radius: var(--br_large);
    color: white;
    height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
}

.header > .note {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1;
    color: var(--clr-afGray);
    background-color: #fee3a5;
    border-left: 1px solid #fdc748;
    border-bottom: 1px solid #fdc748;
    padding: 0.25em 0.5em;
    border-bottom-left-radius: var(--br_small);
    cursor: pointer;
}

.title {
    font-weight: 600;
    font-size: 1.2em;
}

.moveRight {
    margin-left: 1em;
}

.panel nav {
    display: flex;
    justify-content: flex-end;
    column-gap: 1em;
    align-items: center;
}

.close {
    position: absolute;
    z-index: 1;
    left: -26px;
    top: -6px;
    width: 54px;
    height: 54px;
    background-color: var(--clr-afBlue);
    border: 2px solid white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.close svg {
    fill: white;
    width: 27px;
    height: 27px;
}

.content {
    position: relative;
    background-color: white;
    border-bottom-left-radius: var(--br_large);
    border-bottom-right-radius: var(--br_large);
    display: flex;
    flex-direction: column;
    min-height: 0;
    flex-grow: 1;
}

.padding {
    padding: 1em;
}
