.content table {
	border: none;
}

.content td {
	padding-right: 1em;
	line-height: 1.3em;
}

.serviceCircle {
	display: flex;
}

.serviceCircle > div {
	padding: 0 0.5em;
	border-left: 1px solid var(--clr-afGray);
}

.serviceCircle > div:first-child {
	padding-left: 0;
	border-left: none;
}

.content label {
	font-weight: 600;
}
