.panel {
	height: calc(100vh - 78px - 1em);
	position: relative;
}

.content {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: space-between;
}

.content > *:first-child {
	flex-grow: 1;
}

.content > *:last-child {
	width: 260px;
	border-left: 1px solid var(--clr-afGray);
}
