.bar {
    position: relative;
    height: 1.5em;
    margin-bottom: 0.5em;
    background-color: var(--clr-lightGray);
    border-top: 1px solid #707070;
    border-bottom: 1px solid #707070;
    display: flex;
}

.fix {
    background-color: var(--clr-lightBlue);
}

.bar.barStart::before {
    content: "";
    position: absolute;
    left: 0;
    top: -1px;
    bottom: -1px;
    width: 4px;
    background-color: white;
    border-right: 1px solid #707070;
    z-index: 1;
}

.bar.barEnd::after {
    content: "";
    position: absolute;
    right: 0;
    top: -1px;
    bottom: -1px;
    width: 4px;
    background-color: white;
    border-left: 1px solid #707070;
    z-index: 1;
}

.disabled .bar.barStart::before,
.disabled .bar.barEnd::after {
    background-color: var(--clr-lightGray);
}

.group {
    background: repeating-linear-gradient(135deg, var(--clr-lightGray), var(--clr-lightGray) 5px, rgba(255, 255, 255, 0.5) 5px, rgba(255, 255, 255, 0.5) 10px);
}

.empty {
    height: 1.5em;
    margin-bottom: 0.5em;
}

.head {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 0.5em;
}

.head > div {
    padding: 0 0.5em;
    border-bottom-right-radius: var(--br_small);
    position: relative;
}

.head div > div {
    display: none;
}

.head div:hover > div {
    display: block;
}

.disabled {
    background-color: var(--clr-lightGray);
}

.disabled > div {
    opacity: 0.5;
}

.publicHoliday {
    background-color: #b6d767 !important;
}

.regionalHoliday {
    background: repeating-linear-gradient(135deg, #b6d767, #b6d767 5px, rgba(255, 255, 255, 0.5) 5px, rgba(255, 255, 255, 0.5) 10px);
}
