.browser {
	display: flex;
	column-gap: 2px;
	align-items: flex-start;
	background-color: white;
	padding: 2px;
}

div.browser div.floatError {
	position: absolute;
	z-index: 1;
	top: 100%;
	left: 0;
	right: 0;
	margin: 1px 0.5em 0 0.5em;
	background-color: yellow;
	color: var(--clr-afGray);
	font-size: 0.8em;
	line-height: 1.3em;
	font-weight: 600;
	border-radius: var(--br_small);
	padding: 0.25em 0.5em;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}
