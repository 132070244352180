.toolTip {
	position: absolute;
	z-index: 1;
	bottom: 100%;
	left: 50%;
	transform: translateX(-50%);
	margin-bottom: 8px;
	background-color: #f28a2b;
	border-radius: var(--br_small);
	padding: 0.25em 0.5em;
	line-height: 1.3em;
	max-width: 200px;
	color: #444;
}

.toolTip::before {
	content: "";
	position: absolute;
	left: 50%;
	top: 100%;
	margin-left: -8px;
	margin-top: -1px;
	width: 16px;
	height: 10px;
	clip-path: polygon(0 0, 50% 100%, 100% 0);
	background-color: #f28a2b;
}

.toolTip span {
	white-space: nowrap;
	font-size: 0.8rem;
	font-weight: 600;
}
