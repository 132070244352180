.content {
	border-bottom-left-radius: var(--br_large);
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
	background-color: white;
}

.name {
	flex-shrink: 1;
	flex-basis: 29%;
}

.street {
	flex-shrink: 1;
	flex-basis: 29%;
}

.zip {
	flex-shrink: 1;
	flex-basis: 10%;
}

.city {
	flex-shrink: 1;
	flex-basis: 29%;
}

.notes {
	flex: 0 0 60px;
}

.table {
	border-bottom-left-radius: var(--br_large);
}
