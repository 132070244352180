.content {
	border-bottom-left-radius: var(--br_large);
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
	background-color: white;
}

.resultInfo {
	background-color: #fdc748;
	padding: 0.5em;
}

.lastname {
	flex-shrink: 1;
	flex-basis: 30%;
}

.firstname {
	flex-shrink: 1;
	flex-basis: 30%;
}

.gln {
	flex-shrink: 1;
	flex-basis: 30%;
}

.notes {
	flex: 0 0 60px;
}

.table {
	border-bottom-left-radius: var(--br_large);
}
