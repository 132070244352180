.buttons {
    display: flex;
    justify-content: space-between;
    background-color: var(--clr-afBlue);
    border-radius: var(--br_small);
    color: white;
    user-select: none;
}

.buttons > div {
    text-align: center;
    flex: 1 0 auto;
    border-right: 1px solid white;
    line-height: 1.6em;
}

.buttons > div:last-child {
    border-right: none;
}

.buttons > div:hover {
    cursor: pointer;
}

.buttons > .selected {
    background-color: var(--clr-lightBlue);
    color: var(--clr-afGray);
    font-weight: 600;
}

.buttons > div > span {
    position: relative;
}

.count {
    position: absolute;
    top: -4px;
    left: 100%;
    background-color: var(--clr-planning);
    color: var(--clr-afGray);
    font-size: 0.7rem;
    font-weight: 600;
    line-height: 1rem;
    text-align: center;
    border-radius: 10px;
    min-width: 1rem;
    padding: 0 4px;
}
