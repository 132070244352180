.content {
	display: flex;
	justify-content: space-between;
	column-gap: 4em;
}

.content > div {
	flex: 1 0 45%;
	display: flex;
	flex-direction: column;
	row-gap: 1em;
}

.list > li {
	display: flex;
	justify-content: space-between;
	padding: 0.25em 0;
}

.list > li > * {
	flex: 1 1 auto;
}

.list > li > label {
	flex: 0 0 120px;
	font-weight: 600;
}

.fieldset {
	border: 1px solid var(--clr-gray);
	border-radius: var(--br_small);
	padding: 0.5em 1em;
	margin-bottom: 1em;
}

.fieldset:last-child {
	margin-bottom: 0;
}

.fieldset legend {
	font-size: 0.8em;
	font-weight: bold;
	background-color: white;
	padding: 0 0.25em;
	margin-left: -0.25em;
}

.countDays {
	display: flex;
	column-gap: 1em;
}

.countDays ul {
	font-size: 0.85em;
	border-left: 1px solid var(--clr-gray);
	padding-left: 1rem;
	line-height: 1.3em;
}

.small {
	font-size: 0.85em;
}
