.content {
	overflow: hidden;
}

.content li {
	display: flex;
	justify-content: space-between;
	padding: 0 0.75em;
	font-size: 0.8em;
}

.content li:nth-child(odd) {
	background-color: var(--clr-lightGray);
}

.content li > span {
	font-weight: 600;
}

.content li > *:first-child {
	flex: 0 0 100px;
}

.content li > *:not(:first-child) {
	flex: 1 0 20%;
	border-left: 1px solid #ccc;
	padding-left: 0.5rem;
}

.content li.header {
	font-weight: 600;
	line-height: 2em;
	background-color: var(--clr-afBlue);
	color: white;
}

.mark {
	background-color: yellow;
	border-radius: var(--br_small);
	padding: 0 4px;
}

li.button button {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}
