.time {
    position: absolute;
    top: 2px;
    bottom: 2px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding-left: 4px;
    font-size: 0.6em;
    line-height: 1.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.disabled {
    opacity: 0.3;
    cursor: not-allowed;
}
