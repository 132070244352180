.header th {
	position: sticky;
	z-index: 1;
	top: 0; /* Don't forget this, required for the stickiness */
	text-align: left;
	font-size: 0.85em;
	line-height: 1.3em;
	font-weight: 400;
	background-color: white;
}

.header tr:nth-child(2) th {
	top: 36px;
}

.header .columnTitles th {
	background-color: var(--clr-afGray);
	color: white;
	padding: 0.25rem 0.5rem;
	border-right: 1px solid white;
}

.header .columnTitles th:last-child {
	border-right: none;
}

.header .columnTitles th > div {
	display: flex;
	justify-content: space-between;
	align-items: center;
	column-gap: 0.25em;
}

.groupsCell {
	padding: 0 !important;
}

.sortIcon {
	fill: white;
	width: 11px;
	height: 6px;
	flex-shrink: 0;
	opacity: 0.3;
}

.activeSort {
	font-weight: 600 !important;
}

.sortable {
	cursor: pointer;
}

.activeSort .sortIcon {
	opacity: 1;
}

.error {
	width: 50px;
}

.errorIcon {
	width: 16px;
	height: 16px;
}

.groupHeader {
	display: flex;
	justify-content: space-between;
}

.groupHeader > div:not(:last-child) {
	border-right: 1px solid white;
	padding: 0.5rem;
}

.groups {
	display: flex;
	justify-content: space-between;
	column-gap: 1px;
	flex: 1 1 auto;
	align-items: flex-end;
	border-bottom: 3px solid var(--clr-afGray);
	margin-bottom: 1px;
	user-select: none;
}

.groups > div {
	flex: 1 0 auto;
	text-align: center;
	background-color: var(--clr-afBlue);
	color: white;
	cursor: pointer;
	padding: 0.5em 0;
	border-top-left-radius: var(--br_small);
	border-top-right-radius: var(--br_small);
	border-bottom: 1px solid white;
}

.groups > div.selected {
	background-color: var(--clr-afGray);
	font-weight: 600;
	border-bottom-color: var(--clr-afGray);
}
