.progressBar {
	position: relative;
	width: 100%;
	height: 24px;
	border: 1px solid var(--clr-afGray);
	border-radius: var(--br_small);
	overflow: hidden;
	background-color: var(--clr-red);
}

.progressBar > div {
	background-color: var(--clr-green);
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
}

.progressBar > span {
	position: absolute;
	left: 50%;
	top: 50%;
	background-color: rgba(255, 255, 255, 0.7);
	font-size: 0.8em;
	line-height: 1em;
	font-weight: 600;
	padding: 1px 4px;
	border-radius: var(--br_small);
	transform: translate(-50%, -50%);
}
