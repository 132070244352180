.radio {
	display: flex;
	flex-direction: column;
	row-gap: 2px;
}

.radio.horizontal {
	flex-direction: row;
	column-gap: 1em;
}

.label {
	font-size: 0.8em;
	font-weight: 600;
}
