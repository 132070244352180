.day {
	position: relative;
	color: #999;
}

.day > div {
	display: none;
}

.enabled {
	color: var(--clr-afGray);
}

.enabled:hover {
	cursor: pointer;
	border: 1px solid var(--clr-afBlue);
	padding: 0;
}

.day:hover > div {
	display: block;
}

.publicHoliday {
	background-color: #b6d767 !important;
}

.regionalHoliday {
	background: repeating-linear-gradient(135deg, #b6d767, #b6d767 5px, rgba(255, 255, 255, 0.5) 5px, rgba(255, 255, 255, 0.5) 10px);
}
