.user {
    position: relative;
    display: inline-flex;
    padding: 1px 0;
}

.user.marked,
.user.marked .details {
    background-color: var(--clr-planning);
    border-radius: var(--br_small);
}

.user > span {
    padding-right: 6px;
}

.details {
    display: none;
    align-items: center;
    border-radius: var(--br_small);
    border: 1px solid var(--clr-afBlue);
    background-color: #e2ebf5;
    position: absolute;
    z-index: 2;
    padding: 1px 0;
    left: -1px;
    top: -1px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    white-space: nowrap;
}

.user:hover .details {
    display: flex;
}

.user.marked > span:hover > div {
    background-color: var(--clr-planning);
}

.infoIcon {
    width: 16px;
    cursor: pointer;
    margin: 0 6px;
    transition: all 0.15s ease-in-out;
}

.doctorIcon {
    padding: 0 6px;
    cursor: pointer;
    flex-shrink: 0;
    border-radius: 6px;
    display: flex;
    align-items: center;
}

.doctorIcon > svg {
    fill: var(--clr-afGray);
    width: 13px;
    transition: all 0.15s ease-in-out;
}

.infoIcon:hover,
.doctorIcon:hover > svg {
    transform: scale(1.2);
    fill: var(--clr-afBlue);
}

.noName {
    margin-left: 4px;
}
