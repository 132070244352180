.searchFilter {
	position: relative;
	padding: 0.25em 0;
}

.searchFilter input {
	border-radius: var(--br_small);
	padding: 0.5em 0.75em;
	outline: none;
	background-color: var(--clr-lightBlue);
	border: none;
}

.searchFilter svg {
	position: absolute;
	right: 8px;
	top: 3px;
	bottom: 3px;
	height: calc(100% - 6px);
	cursor: pointer;
	opacity: 0.5;
	transition: opacity 0.1s ease-in-out;
}

.searchFilter svg:hover {
	opacity: 1;
}

.searchFilter svg:hover ~ input {
	text-decoration: line-through;
	color: #999;
}
