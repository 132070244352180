.date {
	display: flex;
	column-gap: 0.25em;
	align-items: center;
}

.date > input {
	width: 3em;
	text-align: right;
}

.date > input:last-child {
	width: 4em;
}

.error > input {
	background-color: #f7ccd2;
}
