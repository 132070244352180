.content {
	padding: 0.5em 0.75em;
}

.buttons {
	display: flex;
	justify-content: flex-end;
	column-gap: 1em;
}

.list > li {
	display: flex;
}

.colorRect {
	font-size: 0.8em;
	display: inline;
	padding: 0 0.25em;
}
