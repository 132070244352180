.content {
    padding: 1em;
}

.cols {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 1em;
}

.cols > * {
    flex: 1 1 auto;
}

.number {
    width: 5em;
    text-align: right;
    margin-right: 0.25em;
}

.rows {
    display: flex;
    flex-direction: column;
    row-gap: 1em;
}

.notes {
    padding: 1em 1em 0 1em;
}

.buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1em;
}

.buttons > *:nth-child(2) {
    display: flex;
    justify-content: flex-end;
    column-gap: 1em;
}

.doctor {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 1em;
}

.doctor > div:last-child {
    font-size: 0.83em;
    text-align: right;
}
