.bar {
	position: relative;
	height: 1.5em;
	margin-bottom: 0.5em;
	background-color: var(--clr-lightGray);
	border-top: 1px solid #707070;
	border-bottom: 1px solid #707070;
}

.fix {
	background-color: var(--clr-lightBlue);
}

.bar.barStart::before {
	content: "";
	position: absolute;
	left: 0;
	top: -1px;
	bottom: -1px;
	width: 4px;
	background-color: white;
	border-right: 1px solid #707070;
	z-index: 1;
}

.bar.barEnd::after {
	content: "";
	position: absolute;
	right: 0;
	top: -1px;
	bottom: -1px;
	width: 4px;
	background-color: white;
	border-left: 1px solid #707070;
	z-index: 1;
}

.disabled .bar.barStart::before,
.disabled .bar.barEnd::after {
	background-color: var(--clr-lightGray);
}

.group {
	background: repeating-linear-gradient(135deg, var(--clr-lightGray), var(--clr-lightGray) 5px, rgba(255, 255, 255, 0.5) 5px, rgba(255, 255, 255, 0.5) 10px);
}

.hover {
	border-color: var(--clr-afBlue);
}

.empty {
	height: 1.5em;
	margin-bottom: 0.5em;
}

.head {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 0.5em;
}

.actionTools {
	position: absolute;
	left: 8px;
	top: 3px;
}

.head > div {
	padding: 0 0.5em;
	border-bottom-right-radius: var(--br_small);
	position: relative;
}

.head div > div {
	display: none;
}

.head div:hover > div {
	display: block;
}

.addService {
	opacity: 0.3;
	fill: var(--clr-afBlue);
	transition: transform 0.2s ease-out;
}

.addServiceEnabled {
	opacity: 1;
}

.addServiceEnabled:hover {
	transform: scale(1.2);
	cursor: pointer;
}

.disabled {
	background-color: var(--clr-lightGray);
}

.disabled > div {
	opacity: 0.5;
}

.publicHoliday {
	background-color: #b6d767 !important;
}

.regionalHoliday {
	background: repeating-linear-gradient(135deg, #b6d767, #b6d767 5px, rgba(255, 255, 255, 0.5) 5px, rgba(255, 255, 255, 0.5) 10px);
}
