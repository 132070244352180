.panel {
	border-radius: var(--br_small);
	background-color: #f7ccd2;
	border: 1px solid var(--clr-afRed);
	padding: 1em 0.75em;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	column-gap: 1.5em;
}

.panel svg {
	flex: 0 0 28px;
	width: 28px;
	height: 26px;
	margin-top: 4px;
}

.panel div {
	flex: 1 0 1px;
}
