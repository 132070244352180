.content {
	display: flex;
	flex-direction: column;
	row-gap: 1em;
}

.label {
	display: block;
}

.label > span {
	font-size: 0.8em;
	font-weight: 600;
}

.error {
	background-color: #f7ccd2;
}
