.fieldset {
    border: 1px solid var(--clr-afGray);
    border-radius: var(--br_small);
    padding: 0.5em 1em;
    margin-bottom: 1em;
}

.fieldset:last-child {
    margin-bottom: 0;
}

.fieldset > legend {
    font-size: 0.8em;
    font-weight: bold;
    background-color: white;
    padding: 0 0.25em;
    margin-left: -0.25em;
}

.fieldset > legend > svg {
    fill: var(--clr-afGray);
    height: 12px;
}

.fieldset.foldable.hide {
    border-width: 1px 0 0 0;
    border-radius: 0;
}

.fieldset.foldable.hide > div {
    display: none;
}

.fieldset.foldable > legend {
    cursor: pointer;
    display: flex;
    column-gap: 0.25rem;
    align-items: center;
}
