.item {
    line-height: 1.5em;
    padding: 0 1em;
    min-height: 2.5em;
    position: relative;
    display: flex;
    justify-content: space-between;
    column-gap: 6px;
    align-items: center;
    white-space: nowrap;
}

.item > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1 0 80%;
    column-gap: 6px;
}

.item:nth-child(odd) {
    background-color: var(--clr-gray);
}

.item > svg {
    width: 8px;
    height: 13px;
    opacity: 0.8;
    fill: var(--clr-afGray);
}

.selected {
    background-color: var(--clr-lightBlue) !important;
    color: var(--clr-afBlue) !important;
}

.click:hover > svg,
.selected > svg {
    fill: var(--clr-afBlue);
    opacity: 1;
}

.click:hover {
    background-color: var(--clr-lightBlue) !important;
    cursor: pointer;
    color: var(--clr-afBlue) !important;
}

.item > .icon {
    fill: var(--clr-afBlue);
    transition: transform 0.2s ease-out;
    width: 15px;
    height: auto;
}

.item > .icon:hover {
    transform: scale(1.2);
    cursor: pointer;
}
