.content {
	display: flex;
	justify-content: space-between;
	width: 100%;
	min-height: 0;
}

.content > *:first-child {
	background-color: var(--clr-lightGray);
	flex: 0 0 250px;
	border-bottom-left-radius: var(--br_large);
}

.content > *:not(:first-child) {
	flex: 1 1 auto;
	background-color: white;
	border-bottom-right-radius: var(--br_large);
	overflow-y: scroll;
}

.rightPanel {
	padding: 2em;
	background-color: white;
	border-bottom-right-radius: var(--br_large);
}
