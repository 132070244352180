.month {
	width: calc((100% - 2em) / 3);
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	border-radius: var(--br_small);
	border: 1px solid var(--clr-afGray);
	background-color: var(--clr-lightGray);
	min-width: 200px;
	gap: 1px;
}

.month > div {
	position: relative;
	flex: 1 0 13%;
	text-align: center;
	border: 1px solid transparent;
	background-color: white;
	font-size: 0.85em;
	line-height: 2em;
}

.month > div.click:hover {
	outline: #999 dashed 1px;
	cursor: pointer;
}

.month > div:nth-child(7n + 1),
.month > div:nth-child(7n + 0) {
	background-color: rgba(0, 0, 0, 0.05);
}

.month > div.disabled {
	color: #aaa;
}
.month > div > div {
	position: absolute;
	right: 2px;
	top: 2px;
	width: 12px;
	height: 12px;
	border: 1px solid var(--clr-afGray);
}

.month > nav {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	background-color: var(--clr-afGray);
}

.month svg {
	width: 16px;
	height: 16px;
	fill: white;
	margin: 0 8px;
}

.month svg:hover {
	cursor: pointer;
}

.month svg:first-child:hover {
	margin-left: 7px;
	margin-right: 9px;
}

.month svg:last-child:hover {
	margin-left: 9px;
	margin-right: 7px;
}

.month > nav > span,
.month > span {
	display: block;
	text-align: center;
	width: 100%;
	background-color: var(--clr-afGray);
	color: white;
	line-height: 2em;
}
