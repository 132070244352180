.currentCategory {
	border: 1px solid white;
	border-radius: var(--br_small);
	padding: 4px 0.5em;
	opacity: 0.5;
	white-space: nowrap;
}

.currentCategory:hover {
	opacity: 1;
	cursor: pointer;
}

.currentCategory span::after {
	content: " | ";
}

.currentCategory span:last-child::after {
	content: "";
}

.currentCategory svg {
	fill: white;
	margin-right: 0.75em;
}
