.panel {
	max-height: 100%;
}

.content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100px;
}

.filter {
	padding: 0.25em 0.5em;
}

.list {
	overflow: auto;
	background-color: var(--clr-lightGray);
	flex-grow: 1;
}

.header {
	background-color: var(--clr-afGray);
	color: white;
}

.list > div {
	max-height: 200px;
	overflow-y: scroll;
}

.row {
	display: flex;
	justify-content: space-between;
	padding: 0.25em 0;
}

.row > div {
	flex: 0 1 50%;
}

.row > div:first-child {
	padding-left: 1em;
}

.row > div:last-child {
	padding-right: 1em;
}

:not(.header) .row:nth-child(odd) {
	background-color: var(--clr-gray);
}

.row.selected,
.list .row:hover {
	background-color: var(--clr-lightBlue) !important;
	color: var(--clr-afBlue) !important;
	cursor: pointer;
}
