.smallPanel > header {
	position: relative;
	background-color: var(--clr-afGray);
	color: white;
	border-top-left-radius: var(--br_small);
	border-top-right-radius: var(--br_small);
	padding: 0.5em 0.75em;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.toggleHeader {
	display: flex;
	column-gap: 4px;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
}

.toggleHeader > svg {
	flex: 0 0 14px;
	fill: white;
}

.expand > header > *:first-child {
	flex: 1 1 auto;
}

.expand > header > svg {
	width: 20px;
	fill: white;
	transition: transform 0.2s ease-out;
}

.expand:hover > header > svg {
	transform: scale(1.2);
}

.item > .icon:hover {
	cursor: pointer;
}

.add {
	fill: white;
	cursor: pointer;
}

.jump {
	fill: white;
	cursor: pointer;
	width: 16px;
}

.smallPanel div.search {
	padding: 4px 2px;
	background-color: var(--clr-afGray);
}

.smallPanel div.content {
	border: 1px solid var(--clr-afGray);
	border-top: none;
	border-bottom-left-radius: var(--br_small);
	border-bottom-right-radius: var(--br_small);
	max-height: calc(100% - 27px);
	overflow: hidden;
}

.smallPanel > div,
.smallPanel > ul {
	border-left: 1px solid var(--clr-afGray);
	border-right: 1px solid var(--clr-afGray);
}

.smallPanel > *:last-child {
	border-bottom-left-radius: var(--br_small);
	border-bottom-right-radius: var(--br_small);
	border-bottom: 1px solid var(--clr-afGray);
}

.smallPanel > ul.scroll {
	overflow-y: scroll;
	max-height: calc(100% - 101px);
}

.smallPanel div.tabs > * {
	text-align: center;
}
