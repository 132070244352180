.name {
	display: flex;
	justify-content: space-between;
	column-gap: 0.5em;
	align-items: center;
	margin-bottom: 1em;
}

.name:not(:first-child) {
	margin-top: 1em;
}

.radios {
	display: flex;
	flex-direction: column;
	row-gap: 2px;
}

.radios > label {
	display: flex;
	column-gap: 4px;
	align-items: center;
	cursor: pointer;
}

.embed {
	padding-left: 1em;
}

.otherPublicHolidays {
	margin-top: 1em;
}

.deleteLocalPublicHolidays {
	text-decoration: line-through;
}
