.table {
	border-spacing: 0;
	width: 100%;
}

.table thead {
	font-size: 0.8em;
	font-weight: 600;
}

.table td:first-child > div {
	margin-right: 0;
}

.table td:nth-child(2),
.table td:nth-child(3) {
	width: 40%;
}

.table td:nth-child(4) {
	text-align: right;
}

.table td:not(:last-child) {
	padding-right: 1em;
}
