.radios {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	row-gap: 2px;
}

.label {
	font-size: 0.8em;
}

.radioLabel {
	display: inline-flex;
	justify-content: flex-start;
	align-items: center;
	column-gap: 4px;
	cursor: pointer;
}

.labelField {
	font-size: 0.8rem;
	width: 160px;
	padding: 0 8px;
	line-height: 22px;
	margin-bottom: 0.5em;
	border-radius: 4px;
}

.labelField::placeholder {
	color: var(--clr-afGray);
}

.colors {
	display: flex;
	column-gap: 0.25em;
}

.colors > div {
	width: 17px;
	height: 17px;
	border: 1px solid transparent;
}

.colors > div:not(.selected):hover {
	border-color: var(--clr-afGray);
	border-style: dashed;
	cursor: pointer;
}

.colors > div.selected {
	border-color: var(--clr-afGray);
}
