.panel {
	height: calc(100vh - 78px - 1em);
	position: relative;
}

.roster {
	position: relative;
	display: flex;
	justify-content: space-between;
	column-gap: 3em;
}

.roster > div:first-child {
	flex-grow: 1;
}

.monthSelector {
	margin-bottom: 1em;
}
