.colorIcon {
	width: 14px;
	height: 14px;
	border: 1px solid #707070;
	margin-left: 2px;
	flex-shrink: 0;
}

.action {
	cursor: pointer;
	/* border-style: dashed; */
	outline: 1px #707070 dashed;
	outline-offset: 1px;
}

.action:hover {
	outline: 1px var(--clr-afBlue) solid;
	outline-offset: 1px;
}
