.row {
	display: flex;
	line-height: 2em;
	justify-content: space-between;
	align-items: center;
	column-gap: 1px;
}

.row > div {
	white-space: nowrap;
	/* overflow: hidden; */
	text-overflow: ellipsis;
}

.row > div > span {
	display: inline-block;
	padding: 0 0.5rem;
}

.alt {
	background-color: var(--clr-gray);
}

.error {
	flex: 0 0 60px;
}

.errorIcon {
	width: 16px;
	height: 16px;
	margin-left: 0.5rem;
}

.row:hover {
	background-color: var(--clr-lightBlue);
	cursor: pointer;
}

.noteIcon {
	width: 24px;
	height: 24px;
}
