.table {
	width: 100%;
	border-collapse: collapse;
}

.table tbody {
	background-color: var(--clr-lightGray);
}

.table tbody tr:nth-child(odd) {
	background-color: var(--clr-gray);
}

.table td,
.table th {
	padding: 0.25em 0.5em;
	vertical-align: top;
}

.clickable tr:hover {
	background-color: var(--clr-lightBlue) !important;
	cursor: pointer;
}
