.servicePlanState {
    position: relative;
    border-radius: var(--br_small);
    background-color: var(--clr-planning);
    color: var(--clr-afGray);
    cursor: pointer;
    align-self: stretch;
    padding: 0 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 140px;
}

.servicePlanState > span {
    font-weight: 600;
    font-size: 0.85em;
    line-height: 1.2em;
    padding-right: 0.4em;
}

.bar {
    display: flex;
    justify-content: space-between;
    column-gap: 1px;
    height: 5px;
}

.bar > div {
    background-color: var(--clr-afGray);
    flex: 1 0 auto;
}

.bar > div.active {
    background-color: var(--clr-green);
}

.bar > div.error {
    background-color: var(--clr-afRed);
}

.servicePlanState > svg,
.countErrors {
    position: absolute;
    right: -4px;
    top: 2px;
    width: 16px;
    height: 16px;
}

.countErrors {
    border-radius: 50%;
    background-color: var(--clr-afRed);
    color: white;
    font-size: 0.75em;
    font-weight: 600;
    text-align: center;
    line-height: 16px;
}
