.list {
	margin-top: 2em;
}

.list li:not(:first-child) {
	margin-top: 1em;
}

.log {
	font-size: 0.875em;
}

.showFrom {
	display: flex;
	column-gap: 1em;
	align-items: center;
	margin-top: 0.25em;
}

.showFrom label {
	display: flex;
	column-gap: 4px;
	align-items: center;
	cursor: pointer;
}

.color {
	padding: 0 4px;
	border-radius: var(--br_small);
}

.green {
	background-color: #57d2da;
}

.gold {
	background-color: #dac257;
}

.purple {
	background-color: #b1aaeb;
}
