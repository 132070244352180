.content {
	position: relative;
	width: 14px;
	display: flex;
	padding-top: 4px;
}

.noteIcon {
	width: 24px;
	height: 24px;
}

.count {
	position: absolute;
	left: 100%;
	top: 0;
	display: flex;
	color: white;
	font-size: 0.7rem;
	font-weight: 600;
	line-height: 1rem;
	text-align: center;
	min-width: 1rem;
	margin-left: -8px;
	vertical-align: top;
}

.count > div:first-child {
	background-color: var(--clr-afRed);
	border-radius: 10px 0 0 10px;
	padding: 0 3px 0 4px;
}

.count > div:last-child {
	background-color: var(--clr-afGray);
	border-radius: 0 10px 10px 0;
	padding: 0 4px 0 3px;
}
