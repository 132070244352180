.buttons {
	display: flex;
	justify-content: flex-end;
	column-gap: 1em;
}

.padding {
	padding: 1em;
}

.paddingTop {
	padding-top: 1em;
}
