.start {
    background-color: #52ce90 !important;
    border-radius: var(--br_small) 0 0 var(--br_small);
}

.selected {
    background-color: #52ce90 !important;
}

.disabled {
    opacity: 0.5;
}

.end {
    background-color: #52ce90 !important;
    border-radius: 0 var(--br_small) var(--br_small) 0;
}

.calendar {
    width: 100% !important;
    border: 1px solid var(--clr-afGray);
    border-radius: var(--br_small);
    overflow: hidden;
}

.calendar nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: var(--clr-afGray);
}

.calendar svg {
    width: 16px;
    height: 16px;
    fill: white;
    margin: 0 8px;
    cursor: pointer;
}

.calendar svg:first-child:hover {
    margin-left: 7px;
    margin-right: 9px;
}

.calendar svg:last-child:hover {
    margin-left: 9px;
    margin-right: 7px;
}

.calendar nav span {
    display: block;
    text-align: center;
    width: 100%;
    background-color: var(--clr-afGray);
    color: white;
    line-height: 2em;
}

.calendar > div {
    background-color: var(--clr-lightGray);
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    line-height: 2em;
    gap: 1px;
    font-size: 0.875em;
}

.calendar > div > div {
    flex: 1 0 13%;
    text-align: center;
    background-color: white;
    border: 1px solid transparent;
}

.calendar > div > div:nth-child(7n - 1),
.calendar > div > div:nth-child(7n + 0) {
    background-color: #e4e4e4;
}

.choosen {
    text-align: center;
    padding-bottom: 0.25em;
}

.click:hover {
    outline: #999 dashed 1px;
    cursor: pointer;
}

.hour {
    width: 4em;
    text-align: right;
}

.hours {
    display: flex;
    column-gap: 2em;
    margin-bottom: 1em;
}
