.content {
	line-height: 1.5em;
	overflow: hidden;
}

.content li {
	padding: 0.25em 0.75em;
	display: flex;
	justify-content: space-between;
	column-gap: 1em;
	align-items: center;
}

.content li:nth-child(odd) {
	background-color: var(--clr-lightGray);
}

.content li > div {
	flex: 1 1 10%;
	white-space: nowrap;
}

.content li > div:first-child {
	flex: 0 0 100px;
}

/* .content li:hover {
	background-color: var(--clr-lightBlue);
	cursor: pointer;
} */

.content li > div.icons {
	flex: 0 0 40px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	column-gap: 0.5em;
}

.icon {
	fill: var(--clr-afGray);
	transition: transform 0.2s ease-out;
}

.icon:hover {
	transform: scale(1.2);
	cursor: pointer;
}
