.inputWithPredefinedValues {
	position: relative;
}

.inputWithPredefinedValues > ul {
	position: absolute;
	z-index: 1;
	left: 0;
	top: 100%;
	margin-top: -4px;
	right: 0;
	border: 2px solid var(--clr-afBlue);
	border-top: 1px solid #ccc;
	background-color: var(--clr-lightGray);
	border-bottom-left-radius: var(--br_small);
	border-bottom-right-radius: var(--br_small);
	/* max-height: 100px; */
	/* overflow-y: scroll; */
}

.inputWithPredefinedValues > ul > li {
	padding: 0.25em 0.5em;
}

.inputWithPredefinedValues > ul > li:nth-child(even) {
	background-color: var(--clr-gray);
}

.inputWithPredefinedValues > ul > li:hover,
.selected {
	background-color: var(--clr-lightBlue) !important;
	color: var(--clr-afBlue) !important;
	cursor: pointer;
}
