.content {
	display: flex;
	flex-direction: column;
	row-gap: 2em;
}

.panelContent {
	display: flex;
	justify-content: space-between;
	column-gap: 2em;
	padding: 0.75em;
}

.panelContent > *:first-child {
	flex: 0 0 45%;
}

.panelContent > * {
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
	row-gap: 1em;
}

.panelContent label > span {
	display: block;
	font-size: 0.8em;
	font-weight: 600;
}
