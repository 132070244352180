.templateName {
	display: flex;
	column-gap: 0.5em;
	align-items: center;
}

.templateName span {
	font-size: 0.875em;
}

.points {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0.5em;
	column-gap: 0.5em;
}

.points > *:first-child {
	flex: 1 0 auto;
}

.points:last-child {
	margin-bottom: 0;
}

.points span {
	display: block;
	font-size: 0.875em;
}

.points input {
	width: 3em;
	text-align: right;
}

.name {
	width: 300px;
}

.fieldError {
	background-color: #f7ccd2;
	outline-color: var(--clr-afRed);
}

.days {
	margin-top: 1em;
	display: flex;
	justify-content: space-between;
}

.smallPanelContent {
	padding: 0.5em;
}
