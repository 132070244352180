.menuItem {
	font-size: 1.25em;
	padding: 0.5em 1em;
	font-weight: 600;
	line-height: 1.3em;
	cursor: pointer;
}

.menuItem:hover,
.selected {
	color: var(--clr-afBlue);
}

.selected::before {
	content: "» ";
}
