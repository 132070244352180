.panel {
    height: calc(100vh - 78px - 1em);
    position: relative;
}

.table {
    flex: 1 0 50%;
    overflow-y: scroll;
}

.doctor {
    flex-basis: 40%;
}

.amount {
    flex-basis: 10%;
    text-align: right;
}

.settings {
    flex-basis: 10%;
    text-align: right;
}

.absences {
    flex-basis: 12.5%;
    text-align: right;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
