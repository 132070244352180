.actionTools {
	position: relative;
	width: 16px;
	height: 16px;
}

.actionTools > svg.gear {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 2;
	width: 100%;
	fill: white;
}

.actionTools ul {
	position: absolute;
	z-index: 2;
	left: -4px;
	top: -4px;
	padding-top: 24px;
	background-color: var(--clr-afBlue);
	color: var(--clr-afGray);
	font-weight: 600;
	white-space: nowrap;
	line-height: 2em;
	border: 1px solid #666;
	border-radius: var(--br_small);
	font-size: 0.8rem;
	overflow: hidden;
	text-align: left;
}

.actionTools.up ul {
	top: auto;
	bottom: -5px;
	padding-top: 0;
	padding-bottom: 24px;
}

.actionTools.right ul {
	left: auto;
	right: -4px;
}

.actionTools.dark > svg.gear {
	fill: var(--clr-afGray);
}

.actionTools:hover > svg.gear {
	z-index: 4;
}

.actionTools.dark:hover > svg.gear {
	fill: white;
}

.actionTools li:nth-child(even) {
	background-color: #fcfcfc;
}

.actionTools li:nth-child(odd) {
	background-color: var(--clr-lightGray);
}

.actionTools li {
	padding: 0 0.5em;
	display: flex;
	column-gap: 6px;
	align-items: center;
}

.actionTools li.disabled {
	color: #999;
}

.actionTools li:not(.disabled):hover {
	background-color: var(--clr-lightBlue);
	color: var(--clr-afBlue);
	cursor: pointer;
}

.actionTools li svg {
	fill: var(--clr-afGray);
}

.actionTools li:not(.disabled):hover svg {
	fill: var(--clr-afBlue);
}
