.header {
	display: flex;
	justify-content: space-between;
	background-color: var(--clr-afGray);
	color: white;
}

.header > div {
	border-right: 1px solid white;
	padding: 0.5rem 0;
	display: flex;
	justify-content: space-between;
	column-gap: 0.25rem;
	align-items: center;
	cursor: pointer;
	font-size: 0.85em;
	line-height: 1.3em;
	font-weight: 600;
}

.header > div > span {
	padding-left: 0.5rem;
}

.header > div:last-child {
	border-right: none;
}

.sortIcon {
	fill: white;
	width: 11px;
	height: 6px;
	flex-shrink: 0;
	margin-right: 0.5rem;
}

.header > div.disabled {
	font-weight: 400;
}

.disabled .sortIcon {
	opacity: 0.3;
}

.error {
	flex: 0 0 60px;
}

.errorIcon {
	margin-left: 0.5rem;
	width: 16px;
	height: 16px;
}

.groupHeader {
	display: flex;
	justify-content: space-between;
}

.groupHeader > div:not(:last-child) {
	border-right: 1px solid white;
	padding: 0.5rem;
}

.groups {
	display: flex;
	justify-content: space-between;
	column-gap: 1px;
	flex: 1 1 auto;
	align-items: flex-end;
	border-bottom: 3px solid var(--clr-afGray);
	margin-bottom: 1px;
}

.groups > div {
	flex: 1 0 auto;
	text-align: center;
	background-color: var(--clr-afBlue);
	color: white;
	cursor: pointer;
	padding: 0.5em 0;
	border-top-left-radius: var(--br_small);
	border-top-right-radius: var(--br_small);
	margin-bottom: 1px;
}

.groups > div.selected {
	padding: 0.25em 0;
	background-color: var(--clr-afGray);
	font-weight: 600;
	margin-bottom: 0px;
}
