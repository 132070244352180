#topBar {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	height: 54px;
	background-color: white;
	z-index: 5;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}

#topBar:hover {
	z-index: 6;
}

#topBar h1 {
	position: absolute;
	left: 2rem;
	top: 0;
	font-size: 54px;
	font-weight: 600;
	line-height: 50px;
	color: #f0f0f0;
	text-transform: uppercase;
	font-style: italic;
	user-select: none;
	white-space: nowrap;
}

#topBar div.development {
	position: absolute;
	left: -36px;
	top: -36px;
	width: 72px;
	height: 72px;
	background-color: var(--clr-afGray);
	color: yellow;
	font-weight: 600;
	font-size: 0.9rem;
	text-align: center;
	padding-top: 52px;
	z-index: 1;
	transform: rotate(-45deg);
}

#aerztefonLogo {
	position: absolute;
	right: 1em;
	top: 8px;
}

#aerztefonLogo img {
	width: 240px;
}

#aerztefonLogo > span {
	position: absolute;
	top: 100%;
	right: 0;
	font-size: 0.8em;
	line-height: 1.3em;
	text-align: right;
	white-space: nowrap;
	margin-top: -4px;
}

#menu {
	position: absolute;
	font-size: 1em;
	left: 1em;
	bottom: 0;
	right: 256px;
	z-index: 2;
	line-height: 2em;
	list-style: none;
}

#menu > li > span,
#menu > li > a {
	text-transform: uppercase;
}

#menu a {
	color: var(--clr-afGray);
	font-weight: 400;
	display: block;
}

#menu span.click {
	color: var(--clr-afGray);
	font-weight: 400;
	display: block;
}

#menu a:hover {
	color: var(--clr-afRed);
	text-decoration: none;
}

#menu span.click {
	text-decoration: none;
}

#menu span.click span {
	position: relative;
}

#menu a > span {
	position: relative;
	margin-right: 1em;
}

#menu span.click:hover {
	cursor: pointer;
	color: var(--clr-afRed);
}

#menu ul.appMenu a,
#menu ul.appMenu span {
	padding-left: 2.2em;
	position: relative;
}

#menu ul.appMenu span::before {
	content: "✔";
	line-height: 1.3em;
	position: absolute;
	left: 1em;
	top: 0;
	padding: 0;
}

#menu span.active,
#menu a.active {
	color: var(--clr-afRed);
	font-weight: 600;
}

#menu li {
	white-space: nowrap;
}

#menu li > a,
#menu li > span {
	padding: 0 1em;
}

#menu > li {
	display: inline-block;
	position: relative;
	border-bottom: 1px solid white;
	border: 1px solid transparent;
	border-top-left-radius: var(--br_large);
	border-top-right-radius: var(--br_large);
}

#menu li.disabled a,
#menu li.disabled span,
#menu span.disabled {
	color: #bbb;
	cursor: not-allowed;
}

#menu > li.parent:not(.disabled):hover {
	border: 1px solid #999;
	border-bottom-color: white;
	background-color: white;
}

#menu ul {
	display: none;
	position: absolute;
	min-width: 110%;
	z-index: -1;
	top: 100%;
	left: -1px;
	background-color: white;
	list-style: none;
	border: 1px solid #999;
	line-height: 2em;
	border-top-right-radius: var(--br_large);
	border-bottom-left-radius: var(--br_large);
	border-bottom-right-radius: var(--br_large);
	overflow: hidden;
}

#menu svg.menuArrow {
	width: 13px;
	fill: var(--clr-afGray);
	margin-right: 4px;
	margin-bottom: 1px;
}

#menu li.disabled svg.menuArrow {
	fill: #bbb;
}

#menu span.active svg.menuArrow,
#menu a.active svg.menuArrow {
	fill: var(--clr-afRed);
}

#menu > li:not(.disabled):hover ul {
	display: block;
}

#menu ul li:hover {
	background-color: #efefef;
}

#menu ul.dienstplan {
	list-style: none;
	margin: 0;
}

#menu ul.dienstplan > li {
	position: relative;
	padding-left: 80px;
	padding-bottom: 0.5em;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	margin-bottom: 0.5em;
	min-height: 72px;
}

#menu ul.dienstplan > li > div {
	position: absolute;
	left: 0;
	top: 0;
	width: 64px;
	height: 64px;
}

#menu ul.dienstplan div.timeLine {
	border-top: 1px solid blue;
	margin-top: 10px;
}

#menu ul.dienstplan div.box {
	border: 1px solid #333;
	height: 100%;
	border-radius: var(--br_large);
	display: flex;
	justify-content: center;
	align-items: center;
}

#menu ul.dienstplan div.free {
	background-color: #01e53e;
}

#menu ul.dienstplan div.you {
	background-color: yellow;
	text-align: center;
}

#menu ul.dienstplan div.other {
	background-color: orange;
}

#menu ul.dienstplan div.disabled {
	opacity: 0.5;
}

#menu svg.window {
	width: 20px;
	margin-right: 6px;
}

div.count {
	display: inline-block;
	vertical-align: top;
	margin-top: 2px;
	margin-left: 2px;
	background-color: #fdc748;
	min-width: 18px;
	height: 18px;
	padding: 0 4px;
	border-radius: 9px;
	text-align: center;
	line-height: 18px;
	font-size: 0.8em;
	color: var(--clr-afGray);
	font-weight: 600;
}
