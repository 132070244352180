.viewChooser {
    display: flex;
    column-gap: 1em;
}

.viewChooser svg {
    cursor: pointer;
    fill: var(--clr-afBlue);
}

.viewChooser .selected {
    border-bottom: 5px solid var(--clr-afGray);
}

.viewChooser .selected svg {
    fill: var(--clr-afGray);
}
