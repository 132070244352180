.noteBatch:hover {
	font-weight: 600;
}

.content {
	width: 400px;
	padding: 0.25em;
}

.content > ul {
	margin-bottom: 0.5em;
}

.content li {
	border-radius: var(--br_small);
	margin-bottom: 0.75em;
	background-color: rgba(255, 255, 255, 0.3);
}

.content label {
	margin-left: 0.25em;
	margin-top: 0.25em;
	border-top: 1px solid rgba(0, 0, 0, 0.2);
	padding-top: 0.25em;
	font-size: 0.85em;
}

.note {
	padding: 0.25em;
}

.right {
	text-align: right;
	padding: 0.25em;
}

.title {
	border-bottom: 1px solid var(--clr-afGray);
	margin-bottom: 0.5em;
}

.textarea {
	outline: none !important;
	background-color: transparent;
	padding: 0;
}
