.right {
	text-align: right;
}

.center {
	text-align: center;
}

.errorIcon {
	width: 16px;
	height: 16px;
}

.noteIcon {
	width: 24px;
	height: 24px;
}
