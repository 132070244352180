.timeLegend {
	font-size: 0.8em;
	width: 8px;
}

.timeLegend > div {
	border-top: 1px solid var(--clr-afGray);
	border-bottom: 1px solid var(--clr-afGray);
	border-right: 2px solid var(--clr-afGray);
	position: relative;
	height: 20px;
}

.timeLegend > div:first-child {
	border-top-width: 2px;
}

.timeLegend > div:last-child {
	border-right: none;
	border-bottom: none;
}

.timeLegend span {
	position: absolute;
	right: 100%;
	margin-right: 4px;
	top: -11px;
}
