.table {
	width: 100%;
}

.table th {
	font-weight: 600;
	text-align: right;
}

.table td {
	text-align: right;
}
