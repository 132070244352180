.content {
	background-color: var(--clr-gray);
	border-bottom-right-radius: var(--br_large);
}

.contentTop {
	padding: 0.5em 1em;
	display: flex;
	flex-direction: column;
	row-gap: 0.5em;
	align-items: flex-end;
	width: 100%;
}

.contentTop > *:last-child {
	width: 100%;
}

.radio {
	display: inline-flex;
	column-gap: 6px;
	font-size: 0.9em;
	align-items: center;
	cursor: pointer;
	user-select: none;
}

.indent {
	margin-left: 1.2em;
}
