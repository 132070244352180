.map {
	position: absolute;
	left: 0;
	top: 42px;
	right: 0;
	bottom: 0;
	background-color: white;
	display: flex;
	justify-content: space-between;
	border-bottom-left-radius: var(--br_large);
	border-bottom-right-radius: var(--br_large);
}

.mapPanel {
	flex: 1 0 auto;
}

.sidePanel {
	flex: 0 0 400px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	row-gap: 1em;
	padding: 1em;
	overflow-y: scroll;
}

.mainSidePanel {
	display: flex;
	flex: 1 0 auto;
	column-gap: 1em;
	justify-content: space-between;
}

.serviceCircle {
	display: flex;
	flex: 1 0 auto;
	flex-direction: column;
	row-gap: 1em;
}

.buttons {
	display: flex;
	justify-content: space-between;
}
