.selected {
	background-color: var(--clr-lightBlue) !important;
	color: var(--clr-afBlue) !important;
}

.icons {
	display: flex;
	justify-content: space-between;
	column-gap: 0.5em;
}

.icon {
	fill: var(--clr-afBlue);
	transition: transform 0.2s ease-out;
}

.icon:hover {
	transform: scale(1.2);
	cursor: pointer;
}

.colorIcon {
	border: 1px solid #707070;
	width: 14px;
	height: 14px;
	margin-right: 1em;
}

.text {
	display: flex;
	align-items: center;
	flex-basis: 90%;
	flex-grow: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	column-gap: 0.5em;
}

.text span {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.text span svg {
	margin-right: 4px;
}

.selectable:hover {
	background-color: var(--clr-lightBlue) !important;
	cursor: pointer;
}

.disabled span {
	color: #999;
}
