.content {
	display: flex;
	flex-direction: column;
	row-gap: 1em;
	background-color: white;
	padding: 1em;
	border-bottom-right-radius: var(--br_large);
	border-bottom-left-radius: var(--br_large);
}

.label {
	display: block;
}

.label > span {
	font-size: 0.8em;
	font-weight: 600;
}

.twoCols {
	display: flex;
	justify-content: space-between;
	column-gap: 2em;
}

.twoCols > * {
	flex: 0 1 50%;
}

.radios {
	display: flex;
	column-gap: 1em;
}

.radioLabel {
	display: inline-flex;
	justify-content: flex-start;
	align-items: center;
	column-gap: 6px;
	cursor: pointer;
}

.error {
	background-color: #f7ccd2;
}
