.list {
    overflow: scroll;
    white-space: nowrap;
}

.list > li {
    padding: 2px 0.25em;
}

.list > li:nth-child(odd) {
    background-color: var(--clr-gray);
}
