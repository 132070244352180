.content {
	display: flex;
	flex-direction: column;
	row-gap: 1em;
}

.label {
	display: block;
}

.label > span {
	font-size: 0.8em;
	font-weight: 600;
}

.twoCols {
	display: flex;
	justify-content: space-between;
	column-gap: 2em;
}

.twoCols > * {
	flex: 0 1 50%;
}

.radios {
	display: flex;
	column-gap: 1em;
}

.radioLabel {
	display: flex;
	align-items: flex-start;
	column-gap: 6px;
	cursor: pointer;
}

.radioLabel > input {
	margin-top: 4px;
}

.view {
	display: flex;
	flex-direction: column;
	row-gap: 1em;
}

.view label {
	display: block;
}

.view label > span {
	display: block;
	font-size: 0.8em;
	font-weight: 600;
}

.error {
	background-color: #f7ccd2;
}

.indent {
	margin-left: 1.2em;
}
