.icons {
	display: flex;
	justify-content: flex-end;
	column-gap: 0.5em;
	flex-grow: 1;
}

.icon {
	fill: var(--clr-afGray);
	transition: transform 0.2s ease-out;
	width: 15px;
	height: 15px;
}

.icon:hover {
	transform: scale(1.2);
	cursor: pointer;
}

.text {
	display: flex;
	align-items: center;
	flex-basis: 90%;
	flex-grow: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
