.content {
    display: flex;
    flex-direction: column;
    row-gap: 1em;
}

.medicalPractice {
    padding-top: 0.5em;
    padding-left: 1em;
}

.billingPeriodName {
    display: flex;
    justify-content: space-between;
    column-gap: 0.5em;
    margin-left: 1.2em;
    align-items: center;
}
