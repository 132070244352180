.list {
	max-height: 20em;
	overflow-y: scroll;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
}

.list li {
	line-height: 2em;
	padding: 0 1em;
}

.list li:nth-child(odd) {
	background-color: var(--clr-lightGray);
}

.list li:hover {
	cursor: pointer;
	background-color: var(--clr-lightBlue);
}

.list li.selected {
	background-color: var(--clr-lightBlue) !important;
	color: var(--clr-afBlue) !important;
}

.search {
	padding: 0 1em;
	background-color: white;
}
