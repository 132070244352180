.label {
	display: inline-flex;
	column-gap: 6px;
	cursor: pointer;
	align-items: flex-start;
}

.label > input {
	margin-top: 0.2em;
}
