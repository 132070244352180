.step {
	border-radius: var(--br_small);
	padding: 0.5em 0.75em;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	cursor: pointer;
}

.step h4 {
	font-weight: 600;
	font-size: 1em;
}

.sign {
	flex: 0 0 2em;
	padding-top: 2px;
}

.sign > svg {
	width: 16px;
	height: 16px;
}

.icon {
	flex: 0 0 1.5em;
	text-align: right;
}

.icon > svg {
	width: 14px;
	fill: var(--clr-afGray);
}

.icon > svg:hover {
	cursor: pointer;
	fill: var(--clr-afBlue);
}

.content {
	flex: auto;
}

.ok {
	background-color: var(--clr-gray);
}

.notOk {
	background-color: var(--clr-red);
}
