.twoCols {
	display: flex;
	column-gap: 4em;
	align-items: flex-start;
}

.half > * {
	flex: 1 0 30%;
}

.radios {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	row-gap: 2px;
}

.label {
	font-size: 0.8em;
}

.radioLabel {
	display: inline-flex;
	justify-content: flex-start;
	align-items: center;
	column-gap: 6px;
	cursor: pointer;
}
