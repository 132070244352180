.panel {
	flex-grow: 1;
	position: relative;
	min-height: 200px;
}

.content {
	position: absolute;
	left: 0;
	top: 32px;
	right: 0;
	bottom: 0;
	background-color: var(--clr-gray);
	padding: 4px;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
}

.list {
	position: absolute;
	left: 4px;
	top: 85px;
	right: 4px;
	bottom: 4px;
	border: 1px solid var(--clr-afBlue);
	background-color: var(--clr-lightGray);
	overflow-y: scroll;
}

.list > li {
	padding: 0 0.5em;
	line-height: 1.75em;
}

.list > li:nth-child(even) {
	background-color: var(--clr-gray);
}

.list:not(.locked) > li:not(.selected, .button, .none, .edit):hover {
	background-color: var(--clr-lightBlue);
	cursor: pointer;
}

.list div.deleteBtn {
	cursor: pointer;
}

.list li.selected.multi {
	border-bottom-color: white;
}

.button {
	text-align: center;
}

.button button {
	padding: 0 8px;
	margin: 0.25rem auto;
	line-height: 1.6em;
	font-size: 1em;
	border-radius: var(--br_large);
}

.list li.removeable:hover {
	cursor: pointer;
}
