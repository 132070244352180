.content {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
}

.list {
	flex: 1 0 auto;
	background-color: var(--clr-lightGray);
}

.resultInfo {
	background-color: #fdc748;
	padding: 0.5em;
}
