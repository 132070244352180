.phone {
	display: inline-flex;
	column-gap: 0.5em;
	white-space: nowrap;
}

.error {
	background-color: #f7ccd2;
	border: 1px solid var(--clr-afRed);
	padding: 0 0.25em;
	border-radius: var(--br_small);
}
