.label {
	display: block;
	margin-bottom: 1em;
}

.label > span {
	font-size: 0.8em;
	font-weight: 600;
}

.error {
	background-color: #f7ccd2;
}
