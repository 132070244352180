.service {
    display: flex;
    justify-content: space-between;
    border: 1px solid var(--clr-afGray);
    border-radius: var(--br_small);
    padding: 0.25em 0.5em;
    align-items: center;
    width: 480px;
}

.service > * {
    flex-shrink: 0;
    flex-grow: 0;
}

.favorite {
    flex-basis: 20px;
    margin-top: 1px;
}

.date {
    flex-basis: calc(162px - 0.5em);
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.date > *:first-child {
    flex: 0 0 28px;
}

.serviceTemplate {
    flex-basis: calc(206px - 0.5em);
    display: flex;
    align-items: center;
    column-gap: 6px;
}

.progress {
    flex-basis: 60px;
}

.points {
    flex-basis: 32px;
    text-align: right;
}

.favorite > svg {
    fill: var(--clr-afGray);
    width: 12px;
}

.grouped {
    background: repeating-linear-gradient(135deg, var(--clr-lightGray), var(--clr-lightGray) 5px, rgba(255, 255, 255, 0.8) 5px, rgba(255, 255, 255, 0.8) 10px);
}

.publicHoliday {
    position: relative;
    overflow: hidden;
}

.publicHoliday::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 12px;
    height: 10px;
    border-bottom-right-radius: 3px;
    background-color: #b6d767 !important;
}

.notBooked {
    text-decoration: line-through;
    opacity: 0.5;
}

.weekend {
    font-weight: 600;
}
