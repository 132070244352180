.time {
	position: absolute;
	top: 2px;
	bottom: 2px;
	background-color: rgba(0, 0, 0, 0.5);
	color: white;
	padding-left: 4px;
	font-size: 0.6em;
	line-height: 1.2rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.selected {
	background-image: linear-gradient(90deg, var(--clr-afBlue) 50%, white 50%), linear-gradient(90deg, var(--clr-afBlue) 50%, white 50%), linear-gradient(0deg, var(--clr-afBlue) 50%, white 50%), linear-gradient(0deg, var(--clr-afBlue) 50%, white 50%);
	background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
	background-size: 10px 1px, 10px 1px, 1px 10px, 1px 10px;
	background-position: left top, right bottom, left bottom, right top;
	animation: border-dance 1s infinite linear;
}
@keyframes border-dance {
	0% {
		background-position: left top, right bottom, left bottom, right top;
	}
	100% {
		background-position: left 10px top, right 10px bottom, left bottom 10px, right top 10px;
	}
}

.selectedBeginn {
	background-image: linear-gradient(90deg, var(--clr-afBlue) 50%, white 50%), linear-gradient(90deg, var(--clr-afBlue) 50%, white 50%), linear-gradient(0deg, var(--clr-afBlue) 50%, white 50%), linear-gradient(0deg, var(--clr-afBlue) 50%, white 50%);
	background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
	background-size: 10px 1px, 10px 1px, 1px 10px, 0;
	background-position: left top, right bottom, left bottom, right top;
	animation: border-dance 1s infinite linear;
}

.selectedEnd {
	background-image: linear-gradient(90deg, var(--clr-afBlue) 50%, white 50%), linear-gradient(90deg, var(--clr-afBlue) 50%, white 50%), linear-gradient(0deg, var(--clr-afBlue) 50%, white 50%), linear-gradient(0deg, var(--clr-afBlue) 50%, white 50%);
	background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
	background-size: 10px 1px, 10px 1px, 0, 1px 10px;
	background-position: left top, right bottom, left bottom, right top;
	animation: border-dance 1s infinite linear;
}

.disabled {
	opacity: 0.3;
	cursor: not-allowed;
}

.addable {
	cursor: copy;
}

.removable:hover {
	cursor: pointer;
	opacity: 0.5;
}
