.content {
	background-color: white;
	padding: 1em;
	display: flex;
	flex-wrap: wrap;
	gap: 0.5em;
}

.color {
	width: 48px;
	height: 48px;
	border: 1px solid #707070;
	position: relative;
}

.color > div {
	position: absolute;
	right: 2px;
	top: 2px;
}

.disabled {
	opacity: 0.3;
	cursor: not-allowed;
}

.selected,
.color:not(.disabled):hover {
	outline-style: solid;
	outline-width: 2px;
	outline-offset: 1px;
	outline-color: var(--clr-afBlue);
	cursor: pointer;
}

.buttons {
	background-color: white;
	border-bottom-left-radius: var(--br_large);
	border-bottom-right-radius: var(--br_large);
}
