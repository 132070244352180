.resizable {
	position: absolute;
	left: 12px;
	background-color: var(--clr-gray);
	display: flex;
	justify-content: center;
	align-items: center;
}

.topSpace {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	height: 1px;
	background-color: white;
}

.resizable:not(.selected) {
	cursor: pointer;
}

.resizable.dragging,
.resizable:hover {
	background-color: var(--clr-lightBlue);
}

.handle {
	visibility: hidden;
}

.dragging {
	cursor: row-resize;
}

.selected {
	background-color: var(--clr-lightBlue);
	z-index: 1;
}

.dragging .handle,
.resizable:hover .handle {
	visibility: visible;
}

.resizable span {
	font-size: 0.8em;
}

.resizable svg {
	display: none;
	position: absolute;
	right: 7px;
	bottom: 12px;
	fill: var(--clr-afBlue);
	transition: transform 0.2s ease-out;
}

.resizable svg:hover {
	transform: scale(1.2);
}

.resizable.dragging svg,
.resizable.selected:hover svg {
	display: block;
	cursor: pointer;
}

.locked > .handle {
	display: none;
}

.handle {
	height: 1px;
	position: absolute;
	right: -2px;
	left: -2px;
	cursor: row-resize;
	background-color: var(--clr-afBlue);
}

.handle > div {
	display: none;
	position: absolute;
	left: 50%;
	top: -2px;
	margin-left: -4px;
	border: 1px solid var(--clr-afBlue);
	width: 9px;
	height: 5px;
}

.selected .handle > div {
	display: block;
}

.topHandle {
	top: 0;
}

.bottomHandle {
	bottom: 0;
}
