.list {
	background-color: var(--clr-lightGray);
}

.list > li {
	line-height: 1.5em;
	height: 2.5em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0 0.5em 0 0.75em;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.list > li:nth-child(even) {
	background-color: var(--clr-gray);
}

.list > li:last-child {
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
}

.none {
	font-style: italic;
}
