.time {
	display: flex;
	column-gap: 0.25em;
	align-items: center;
}

.time > input {
	width: 3em;
	text-align: right;
}

.error > input {
	background-color: #f7ccd2;
}
