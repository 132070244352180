.removeTime {
	/* border-style: dashed !important; */
	opacity: 0.5;
	/* cursor: no-drop; */
	cursor: pointer;
	/* background-color: transparent !important; */
}

.removeDay {
	background-color: transparent !important;
	cursor: pointer;
}

.disabled {
	cursor: not-allowed;
	color: #999;
}

.today {
	font-weight: 600;
	outline: 1px dashed var(--clr-afBlue);
}
