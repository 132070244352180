.points {
	display: inline-block;
	background-color: var(--clr-planning);
	border-radius: var(--br_large);
	padding: 0 4px;
	min-width: 24px;
	font-size: 0.8em;
	font-weight: 600;
	text-align: center;
}
