.flyingError {
	position: relative;
}

.error {
	position: absolute;
	z-index: 1;
	top: 100%;
	margin-top: 2px;
	left: 0;
	background-color: #fdc748;
	color: var(--clr-afGray);
	font-size: 0.875em;
	line-height: 1.3em;
	border-radius: var(--br_small);
	padding: 0.5em;
	border: 1px solid var(--clr-afGray);
}
