.roster {
    position: relative;
    display: flex;
    justify-content: space-between;
    column-gap: 3em;
}

.roster > div:first-child {
    flex-grow: 1;
}

.monthSelector {
    margin-bottom: 1em;
}

.sidePanel {
    width: 300px;
    flex-shrink: 0;
    position: relative;
    /* display: flex;
    flex-direction: column;
    row-gap: 1em;
    justify-content: flex-start; */
}

.sidePanel > div {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
}
