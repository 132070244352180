.selected {
	background-color: var(--clr-lightBlue) !important;
	color: var(--clr-afBlue);
}

.colorIcon {
	border: 1px solid #707070;
	width: 14px;
	height: 14px;
	margin-right: 1em;
}
