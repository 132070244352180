.colorPanel {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
}

.colorBar {
	flex: 0 0 16px;
	border-top-left-radius: var(--br_large);
	border-bottom-left-radius: var(--br_large);
}

.colorBar.green {
	background-color: #57d2da;
}

.colorBar.gold {
	background-color: #dac257;
}

.colorBar.purple {
	background-color: #b1aaeb;
}

.content {
	flex: 1 0 20px;
	background-color: var(--clr-lightGray);
	border: 1px solid var(--clr-gray);
	border-left: none;
	padding: 1em;
	border-top-right-radius: var(--br_large);
	border-bottom-right-radius: var(--br_large);
}

.content.narrow {
	padding: 0.5em 1em;
}
