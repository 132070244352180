.header {
	display: flex;
	justify-content: flex-start;
	text-align: center;
	line-height: 1.5em;
}

.header > div {
	flex-basis: calc(100% / 7);
	flex-shrink: 0;
}

.month {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	border-left: 1px solid #aaaaaa;
	border-top: 1px solid #aaaaaa;
}

.month > div {
	flex-basis: calc(100% / 7);
	flex-shrink: 0;
	border-right: 1px solid #aaaaaa;
	border-bottom: 1px solid #aaaaaa;
	min-height: 10em;
	position: relative;
	padding-top: 2em;
}

.disabled {
	background-color: var(--clr-lightGray);
	color: #999;
}

.head {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	padding: 0.25em 0.5em;
}
