.col4 {
	flex-basis: 25%;
}

.col2 {
	flex-basis: 50%;
}

.col ul {
	background-color: var(--clr-lightGray);
}
