.dateTimePlanContent {
	height: 520px;
	padding: 1em 0.5em 0.5em 28px;
	border: 1px solid var(--clr-afGray);
	border-top: none;
	border-bottom-left-radius: var(--br_small);
	border-bottom-right-radius: var(--br_small);
	display: flex;
}

.dateTimePlanContent > div {
	position: relative;
}

.disabled {
	opacity: 0.5;
	transition: opacity 0.2s ease-in-out;
}

.disabled:hover {
	opacity: 1;
	cursor: copy;
}

.trash {
	fill: white;
	transition: transform 0.2s ease-out;
}

.trash:hover {
	transform: scale(1.2);
	cursor: pointer;
}

.newTime {
	position: absolute;
	left: 12px;
	width: 26px;
}

.newTime:hover {
	background-color: green;
	cursor: copy;
}
