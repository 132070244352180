.editContainer {
	position: relative;
	flex-grow: 1;
}

.editString {
	background-color: rgba(255, 255, 255, 0.7);
	border: none;
	line-height: 1.5em;
	height: 1.5em;
	margin: 0.5em 0;
	outline: none;
}

.mutableString {
	border-bottom: 1px dashed var(--clr-afBlue);
	max-width: calc(100% - 16px);
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 1.2em;
}

.error {
	position: absolute;
	z-index: 1;
	top: 100%;
	margin-top: 0.5em;
	left: -0.5em;
	right: -0.5em;
	background-color: #fdc748;
	color: var(--clr-afGray);
	font-size: 0.875em;
	line-height: 1.3em;
	border-radius: var(--br_small);
	padding: 0.5em;
	border: 1px solid var(--clr-afGray);
	white-space: normal;
}
