.yearSelector {
	display: flex;
	align-items: center;
}

.yearSelector svg {
	fill: var(--clr-afGray);
	cursor: pointer;
	width: 20px;
}

.yearSelector svg:hover {
	fill: var(--clr-afBlue);
}
