.tabHeader {
    display: flex;
    justify-content: space-between;
    column-gap: 1px;
    align-items: flex-end;
    user-select: none;
}

.tabHeader a {
    font-weight: 400;
}

.tabHeader a:hover {
    text-decoration: none;
}

.tabHeader > * {
    background-color: var(--clr-afBlue);
    color: white;
    border-top-left-radius: var(--br_small);
    border-top-right-radius: var(--br_small);
    display: flex;
    align-items: center;
}

.tab {
    display: flex;
}

.tab > a {
    display: block;
    font-size: 1.2em;
    color: white;
    line-height: 42px;
    padding: 0 1em;
    white-space: nowrap;
    border-top-left-radius: var(--br_small);
    border-top-right-radius: var(--br_small);
}

.tab > a:hover {
    line-height: 46px;
}

.tab.active > a {
    background-color: white;
    color: var(--clr-afGray);
}

.tabHeader > :last-child {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    border-top-right-radius: var(--br_large);
    padding: 0 1em;
    height: 42px;
    margin-top: 4px;
}
